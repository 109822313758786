<template>
  <div class="menu_list">
    <!-- 贷款数据 -->
    <div style="background-color: #f5f9f8">
      <div class="dik" v-for="item in param1" :key="item.createrId">
        <div class="topTitle">
          <span class="title">{{ item.title }}</span>
          <div class="approvalType">
            <div class="innerBox">
              <div>
                <!-- <van-icon name="fire" color="red" /> -->
              </div>
              <div class="approvalText">极速审批</div>
            </div>
          </div>
        </div>
        <div class="bottomContent">
          <!-- 图片 -->
          <img :src="item.logoUrl" alt="图片已损坏" />
          <!-- 描述 -->
          <div class="neirong">
            <div class="txt">
              <div class="Big">{{ item.priceSection }}</div>
              <div class="text">万元</div>
            </div>
            <div class="txt">
              <van-icon name="play" color="#F1E3CF" />
              <span>{{ item.maxRate }}%起,可贷款{{ item.maxTime }}个月</span>
            </div>
            <div class="txt">
              <van-icon name="play" color="#F1E3CF" />
              <span>{{ item.creditTypeName }}</span>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="applicationBtn">
            <!-- bindtap="tiaozhuan" -->
            <button @click="apply(item) ">立即申请</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dklb",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      param1: "",
      param2: "",
      obj: {},
      propsList: [],
      identityObj: {},
      userInfo: {},
      access_token: '',
      refresh_token: '',
      identityObjList: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    //隐藏头部和底部
    const header = document.querySelector(".header"); // 修改选择器以匹配你的需要
    // const outerElement = header.parentElement;
    // const outerClassName = outerElement.className;
    const noShow = document.querySelector(".noShow");
    const homeManage = document.querySelector(".home-manage");
    const footer = document.querySelector(".footer");

    header.style = "display:none";
    footer.style = "display:none";
    noShow.style = "display:none";
    homeManage.style = "padding-top:0;width:100vw;";
    this.initParams();
    if (typeof wx !== "undefined" && wx.miniProgram) {
      this.initWx();
    }
  },
  methods: {
    initParams() {
      const queryParams = this.$route.query;
      this.obj = queryParams;
      this.param1 = JSON.parse(decodeURIComponent(queryParams.financeProductId));
      this.identityObj =  queryParams.identityObj;
      this.userInfo =  queryParams.userInfo;
      this.access_token =  queryParams.access_token;
      this.refresh_token =  queryParams.refresh_token;
      this.identityObjList = this.$route.query;
      console.log(this.param1);
      // console.log(this.param2);
    },
    initWx() {
      wx.config({
        debug: false,
        appId: "wx1b4a19d2537a3306",
        timestamp: Math.floor(Date.now() / 1000),
        nonceStr: Math.random().toString(36).substr(2, 15),
        signature: signature, // 确保 signature 正确
        jsApiList: ["scanQRCode", "chooseImage", "navigateTo"],
      });

      wx.ready(() => {
        console.log("WeChat SDK is ready.");
      });
    },
    apply(e){
      console.log(e);
     this.$router.push({name:'wxf',query: {
      financeProductId:JSON.stringify(e),
      identityObj:this.identityObj,
      userInfo:this.userInfo,
      access_token:this.access_token,
      refresh_token:this.refresh_token
     }})
    }
  },
};
</script>
<style lang="less" scoped>
/* pages/dkcs/dkcs.css */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Source Han Sans CN", sans-serif;
}

div {
  box-sizing: border-box;
}

.menu_list {
  width: 100vw;
  height: 100vh;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-around;
  background: #f2f2f2;

  .menu-item {
    font-family: "Source Han Sans CN", sans-serif;
    font-weight: 400;
    font-size: 20px; /* 调整字体大小 */
    color: #a08926;
    text-align: center;
    width: 5%; /* 调整宽度 */
    height: 20px; /* 调整高度 */
    line-height: 20px; /* 调整行高 */
  }

  .menu-text {
    text-align: right;
    width: 100%;
    height: 100%;
    position: relative;
    padding-right: 20px; /* 调整padding */
    border-radius: 10px; /* 调整border-radius */
    background: #f3eadb;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-title {
    text-align: right;
    width: 100%;
    height: 100%;
    position: relative;
    padding-right: 20px; /* 调整padding */
    text-decoration: underline; /* 下滑线 */
    border-radius: 10px; /* 调整border-radius */
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-text:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 32px; /* 调整top */
    right: -30px; /* 调整right */
    border-top: 20px solid transparent; /* 调整border-top */
    border-bottom: 24px solid transparent; /* 调整border-bottom */
    border-left: 24px solid #f3eadb; /* 调整border-left */
  }
}

.dik {
  margin-top: 5px;
    padding-top: 13px;
    background: white;

  .topTitle {
    width: 100%;
    display: flex;

    .title {
      width: 25%;
      margin-left: 12px;
      height: 33px;
      font-family: "Source Han Sans CN", sans-serif;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
    }

    .approvalType {
      margin-left: 14px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f3eadb;
      border-radius: 30px;

      .innerBox {
        width: 80%;
        display: flex;
      }

      .approvalText {
        font-size: 18px;
        text-align: center; /* 调整字体大小 */
        color: #a08926;
      }
    }
  }

  .bottomContent {
    display: flex;
    flex-direction: row;
    padding: 10px 10px; /* 调整padding */
    // padding-left: 10px; /* 调整padding-left */

    img {
      width: 100px; /* 调整width */
      height: 100px; /* 调整height */
      border-radius: 10px; /* 调整border-radius */
    }

    .neirong {
      font-family: "Source Han Sans CN", sans-serif;
      font-weight: 400;
      padding-left: 32px; /* 调整padding-left */

      .txt {
        // width: 380px; /* 调整width */
        display: flex;
        // overflow: hidden;
        // text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px; /* 调整字体大小 */

        .Big {
          font-size: 18px; /* 调整字体大小 */
          font-family: "Source Han Sans CN", sans-serif;
          font-weight: bold;
          color: #d25731;
        }

        .text {
          color: #d25731;
          font-size: 18px; /* 调整字体大小 */
          padding-top: 13px; /* 调整padding-top */
        }

        .play {
          padding-left: 0; /* 调整padding-left */
        }

        span {
          display: inline-block;
          width: 126px; /* 调整width */
          height: 30px; /* 调整height */
          text-align: justify;
          font-weight: 400;
          font-size: 20px; /* 调整字体大小 */
          color: #999999;
        }
      }
    }

    .applicationBtn {
      display: block;
      width: 400px; /* 调整width */
      position: relative;

      button {
        width: 80px;
        height: 30px;
        background: #1c1c1c;
        border-radius: 50px;
        font-size: 12px !important;
        background: black;
        color: white;
        position: absolute;
        bottom: 5px;
        right: 10px;
        // margin-left: 0;
      }
    }
  }
}

.goods_more {
  .goods_more_btn {
    display: block;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 40px; /* 调整border-radius */
    height: 80px; /* 调整height */
    line-height: 80px; /* 调整line-height */
    text-align: center;
    font-size: 28px; /* 调整字体大小 */
    color: rgba(35, 38, 40, 0.7);
  }
}
</style>
